import {useState} from "react";
import { 
    Text, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    Card, 
    ModalOverlay, 
    Button, 
    Flex,
    Box,
    Slider, 
    SliderTrack, 
    SliderFilledTrack, 
    SliderThumb,
    Icon,
    Grid,
    GridItem } from "@chakra-ui/react";

import {useEffect}  from "react";

import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider } from 'ethers'

import InvoiceBg from "../../../assets/img/InvoiceBg.png";
import { HSeparator } from "../../../components/separator/Separator";
import { FaMoneyBillWave, FaWallet } from 'react-icons/fa';
import { IoLogoUsd } from "react-icons/io";

const WalletTopupModal = ({isOpen, onClose, onDisconnect, onRefresh, payFromWallet, walletBalance, amount, coin}) => {

    const [walletName, setWalletName] = useState('');
    // console.log(`amount: ${amount}`)
    const amountF = parseFloat(amount);
    // console.log(`amountF: ${amountF}`)
    const walletBalanceF = parseFloat(walletBalance);

    const enoughFunds = amountF <= walletBalanceF;

    const [sliderValue, setSliderValue] = useState(amount);
    // console.log(`sliderValue: ${sliderValue}`)

    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()

    const correctNetwork = chainId === 137;

    useEffect(() => {
        setSliderValue(amount);
    }, [amount]);


    // // useEffect(() => {
    //     console.log(walletProvider)

    //   const ethersProvider =  new BrowserProvider(walletProvider);
    //   const signer = await provider.getSigner()

    //   console.log(ethersProvider.getSigner())
    // }, [walletProvider]);

    const handleSliderChange = (value) => {
        // console.log(`handleSliderChange: ${value}`)
        // console.log(`handleSliderChange: ${typeof amountF}`)
        // console.log(`handleSliderChange: ${value*(walletBalanceF - amountF)/100}`)
        
        if (value === 0) {
            setSliderValue(amountF.toFixed(4));
        } else if (value === 100) {
            setSliderValue(walletBalanceF);
        } else {
            setSliderValue((amountF + value*(walletBalanceF - amountF)/100).toFixed(1));
        }
    };

    const walletTopup = () => {
        console.log(`sliderValue: ${sliderValue}`)
        payFromWallet(sliderValue)
    }

    // console.log(`${enoughFunds}: ${amountF} > ${walletBalanceF}`)
    return (
    <Modal 
        isCentered 
        motionPreset='slideInBottom'
        isOpen={isOpen} 
        onClose={onClose} 
        size="xs">
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropBlur='8px'/>
        <ModalContent>
            <Card
                backgroundImage={InvoiceBg}
                backgroundRepeat='no-repeat'
                bgSize='cover'
                bgPosition='10%'
                borderRadius="5px 5px 0 0" 
                p="20px"
                pb="20px">
                
                <Text fontSize="xl" mt="0px" fontWeight='bold'>
                    Top Up
                </Text>
            </Card>
            <ModalCloseButton />
            <ModalBody p={'10px 30px 30px'} textAlign="center">

                <Text fontSize="md" mt="10px" fontWeight='regular'>
                    Connected wallet: {address}
                </Text>

                {/* <Button onClick={onDisconnect} 
                    variant='brand' 
                    size="lg" 
                    mt='15px' 
                    w="100%">
                    Disconnect Wallet
                </Button> */}

                <Flex align='center' mt='15px' mb='15px'>
                    <HSeparator />
                </Flex>

                <Text fontSize="md" mt="10px" fontWeight='regular'>
                    Payments are supported in {coin}.
                </Text>

                <Flex flexDirection='row' mt='20px' w='100%'>
                    <Icon as={FaMoneyBillWave} color="green.500" mr="20px" w="40px" h="40px" />

                    <Flex align='center' justify='center' w='100%'>
                        <Flex flexDirection='column' me='20px' w='100%'>
                            <Text color='white' fontSize='sm' fontWeight='500'>
                            Top up with:
                            </Text>
                            <Text
                                color='white'
                                fontSize={{ sm: "18px", lg: "26px" }}
                                fontWeight='700'
                                lineHeight='100%'>
                                {sliderValue} {coin}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>

                {(enoughFunds && correctNetwork) && (
                    <>
                        <Text fontSize="md" mt="15px" mb="5px" fontWeight='regular'>
                            Adjust the amount to top up.
                        </Text>
                        <Slider 
                            aria-label='slider-value' 
                            defaultValue={0} 
                            min={0} 
                            max={100} 
                            step={1}
                            onChange={handleSliderChange}>
                            <SliderTrack>
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb boxSize={8}>
                                <Box color='green'  as={IoLogoUsd} />
                            </SliderThumb>
                        </Slider>
                    </>
                )}

                <Flex flexDirection='row' mt='20px' w='100%'>
                    <Icon as={FaWallet} color="blue.500" mr="20px" w="40px" h="40px" />

                    <Flex align='center' justify='center' w='100%'>
                        <Flex flexDirection='column' me='20px' w='100%'>
                            <Text color='white' fontSize='sm' fontWeight='500'>
                            You balance:
                            </Text>
                            <Text
                                color='white'
                                fontSize={{ sm: "18px", lg: "26px" }}
                                fontWeight='700'
                                lineHeight='100%'>
                                {walletBalanceF} {coin}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>

                {(enoughFunds && correctNetwork) && (
                    <Button onClick={walletTopup} 
                        variant='brand' 
                        size="lg" 
                        mt="20px" 
                        w="100%">
                        Top up
                    </Button>
                )}

                {(!enoughFunds && correctNetwork) && (
                <>
                    <Text fontSize="md" mt="20px" fontWeight='regular' color='red'>
                        You do not have enough {coin} in your wallet. Please add to your balance and try again.
                    </Text>
                    <Button onClick={onRefresh} 
                        variant='brand' 
                        size="lg" 
                        mt="20px" 
                        w="100%">
                        Refresh balance
                    </Button>
                </>
                )}

                {!correctNetwork && (
                <>
                    <Text fontSize="md" mt="20px" fontWeight='regular' color='red'>
                        You need to change network to Polygon on your external wallet.
                    </Text>
                </>
                )}

                <Flex align='center' mt='15px' mb='15px'>
                    <HSeparator />
                </Flex>

                <Button onClick={onClose} 
                    variant='brand' 
                    size="lg" 
                    w="100%">
                    Close
                </Button>

            </ModalBody>
            </ModalContent>
    </Modal>
    );
}

export default WalletTopupModal;
