import { useState, useEffect } from "react";
import validator from "validator";
import { useNavigate } from 'react-router';
import { NavLink } from "react-router-dom";

import { ApiHeader } from '../../../_helpers/ApiHeader';
import { SetJwtAuth } from '../../../_helpers/Auth';

import SetPinModal from '../../../views/modals/setcode/setcode';

import { FcGoogle } from "react-icons/fc";

import { ethers } from 'ethers';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import { useGoogleLogin } from '@react-oauth/google';

// Custom components
import { HSeparator } from "../../../components/separator/Separator";
import PhoneNumberInput from '../../../components/phoneNumberInput/phoneNumberInput';
import DefaultAuth from "../../../layouts/auth/auth";
// Assets
import illustration from "../../../assets/img/auth.png";
import illustrationMobile from "../../../assets/img/authMobile.png";
import { BiWallet } from "react-icons/bi";
import { MdRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdEmail } from 'react-icons/md';
import { AiFillPhone } from 'react-icons/ai';

const LoginPage = () => { 

  const toast = useToast();
  const navigate = useNavigate()

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [isSupported, setIsSupported] = useState(false);

  const [loginType, setLoginType] = useState('');
  const [stage, setStage] = useState(0);

  const selectLoginType = (logintype) => {
    setLoginType(logintype);
    setStage(1);
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const pinSet = (wallet) => {
    navigate('/admin/home');
  }

  const verifyGoogleCode = (code) => {
    if (code === '') {
      toast({
        title: 'Error.',
        description: "Credentials can not be empty",
        status: 'error',
      })
      return;
    }
    setIsLoading(true);


    fetch(process.env.REACT_APP_API_URL+'auth/login-google-code', {
      method: 'POST',
      body: JSON.stringify({
        "code" : code
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.status === 1) {
        SetJwtAuth(data.tokens.access.token);
        if (data.newUser) {
          openModal();
        } else {
          navigate('/admin/home')
        }
      } else {
        console.log(data.message)
        setStage(0);
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      console.log(tokenResponse.code);

      verifyGoogleCode(tokenResponse.code)
      
    },
    flow: 'auth-code',
    onError: (error) => {
      toast({
        title: 'Error.',
        description: "Error, please try again",
        status: 'error',
      })
    }
  });

  const loginSelection = () => {
    return (
    <>
        <Text fontSize="md" mt="10px" fontWeight='regular' 
            color={textColorSecondary}>
            Select a way to login. We use passwordless login and you will receive one time code unless you login with social account.
        </Text>
        <Button 
            onClick={()=>selectLoginType('email')} 
            leftIcon={<Icon as={MdEmail} />}
            variant='brand' 
            mt="20px" 
            size="lg" 
            w="100%">
            Email
        </Button>
        
        <Button 
            onClick={()=>selectLoginType('phone')} 
            leftIcon={<Icon as={AiFillPhone} />}
            variant='brand' 
            mt="20px" 
            size="lg" 
            w="100%">
            Phone
        </Button>
        
        <Flex align='center' mt='25px' mb='5px'>
          <HSeparator />
          <Text color={textColor} mx='14px'>
            or
          </Text>
          <HSeparator />
        </Flex>

        <Button 
          onClick={()=>googleLogin()} 
          leftIcon={<Icon as={FcGoogle} />}
          variant='white'
          mt="20px" 
          size="lg" 
          w="100%">
          Continue with Google
        </Button>
    </>
    )
  }  

  const loginFields = () => {
    return (
    
      <FormControl>
      <FormLabel
        display='flex'
        ms='4px'
        fontSize='sm'
        fontWeight='500'
        color={textColorSecondary}
        mb='8px'>
        Enter your {loginType}<Text color={brandStars}>*</Text>
      </FormLabel>
      {loginType === 'email' && (
        <Input
          variant='auth'
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          type='email'
          placeholder={loginType}
          mb='24px'
          fontWeight='500'
          size='lg'
          onChange={(e) => setUsername(e.target.value)}
        />
      )}
      {loginType === 'phone' && (
        <PhoneNumberInput
          variant='auth'
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          type='email'
          placeholder={loginType}
          mb='24px'
          fontWeight='500'
          size='lg'
          setSupported={(e) => setIsSupported(e)}
          onChange={(e) => {
            setUsername(e.replace(/\s+/g, ''))
          }}
        />
      )}

      <Flex w="100%" direction='row' gap={2}>
        <Button onClick={handleBack} 
            variant='outline' 
            size="lg" 
            w="100%">
            Back
        </Button>
        <Button
          fontSize='sm'
          variant='brand'
          fontWeight='500'
          w='100%'
          h='50'
          mb='24px'
          isLoading={isLoading}
          onClick={handleSubmit}>
          Start
        </Button>
      </Flex>
    </FormControl>
    )
  }  

  const handleBack = () => {
    setLoginType('');
    setStage(0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (loginType === 'email') {
      if (validator.isEmail(username)) {
        loginEmail();
      } else {
        setIsLoading(false);
        toast({
          title: 'Error.',
          description: "Email is not valid",
          status: 'error',
        })
      }
    } else if (loginType === 'phone') {
      if (validator.isMobilePhone(username, 'any', { strictMode: true })) {
        if (isSupported) {
          loginPhone();
        } else {
          setIsLoading(false);
          toast({
            title: 'Error.',
            description: "Phone login not available in selected country.",
            status: 'error',
          })
        }
      } else if (validator.isMobilePhone(username, 'any', { strictMode: false })) {
        setIsLoading(false);
        toast({
          title: 'Error.',
          description: "Phone number must be in interantional format with + and country code",
          status: 'error',
        })
      } else {
        setIsLoading(false);
        toast({
          title: 'Error.',
          description: "Not recognised phone number",
          status: 'error',
        })
      }
    }
  }

  const loginEmail = () => {

    console.log(process.env.REACT_APP_API_URL)
    fetch(process.env.REACT_APP_API_URL+'auth/login-email', {
      method: 'POST',
      body: JSON.stringify({
        "email" : username,
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.tokens) {
        navigate(`/auth/verification?m=${btoa(username)}&t=${btoa(data.tokens)}`)
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
          title: 'Error.',
          description: "Error, please try again",
          status: 'error',
        })
        console.log("fetch error: " + err);
        setIsLoading(false);
    });
  }

  const loginPhone = () => {
    fetch(process.env.REACT_APP_API_URL+'auth/login-phone', {
      method: 'POST',
      body: JSON.stringify({
        "phone" : username,
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsLoading(false);
      console.log(data)
      if (data.tokens) {
        navigate(`/auth/verification?m=${btoa(username)}&t=${btoa(data.tokens)}`)
      } else {
        console.log(data.message)
        toast({
            title: 'Error.',
            description: data.message,
            status: 'error',
          })
      }
    })
    .catch(err => {
      toast({
        title: 'Error.',
        description: "Error, please try again",
        status: 'error',
      })
      console.log("fetch error: " + err);
      setIsLoading(false);
    });
  }

  return (
    <>
      <DefaultAuth illustrationBackground={illustration} illustrationBackgroundMobile={illustrationMobile} image={illustration}>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              PayPangea
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              Start using crypto payments in a few easy steps!
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            {stage === 0 && loginSelection()}
            {stage === 1 && loginFields()}
          </Flex>
        </Flex>
      </DefaultAuth>

      <SetPinModal isOpen={isModalOpen} onClose={closeModal} pinSet={pinSet}/>
    </>
  );
}

export default LoginPage;