import {useState, useEffect} from "react";
import { useNavigate } from 'react-router';

import transakSDK from '@transak/transak-sdk';
import { createWeb3Modal, defaultConfig, useWeb3Modal, useDisconnect, useWeb3ModalProvider, useWeb3ModalAccount, useWeb3ModalEvents } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers'

// Chakra imports
import { 
  Button,
  Input, 
  Box,
  Link,
  Image,
  Flex, 
  Text, 
  useToast,
  useBreakpointValue,
  Icon, 
  IconProps,
  GridItem,
  Grid,
  useColorModeValue } from "@chakra-ui/react";

// Custom components

import EVENTS from '../../../../_helpers/events';
import { GetUserData } from '../../../../_helpers/Auth';
import LoginModal from '../../../../views/modals/login/login';
import PinInputModal from '../../../../views/modals/pin/pin';
import PangeaCardModal from '../../../../views/modals/pangeacard/pangeacard';
import OutcomeModal from '../../../../views/modals/outcome/outcome';
import Overlay from '../../../../views/modals/overlay/overlay';
import TopUpModal from '../../../../views/modals/topup/topup';
import WalletTopUpModal from '../../../../views/modals/walletconnecttopup/walletconnecttopup';

import SetPinModal from '../../../../views/modals/setcode/setcode';
import Card from "../../../../components/card/Card.js";

import payPangeaLogoBlack from "../../../../assets/img/PayPangeaLogoBlack.png";
import payPangeaLogoWhite from "../../../../assets/img/PayPangeaLogoWhite.png";

import { ApiHeader } from '../../../../_helpers/ApiHeader';

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import InvoiceBg from "../../../../assets/img/InvoiceBg.png";


const projectId = 'de93e60aa3956c4d46ac74104bfd4415'

const erc20Abi = [
  "function name() view returns (string)",
  "function symbol() view returns (string)",
  "function balanceOf(address) view returns (uint)",
  "function transfer(address to, uint amount)",
  "event Transfer(address indexed from, address indexed to, uint amount)"
];

// 2. Set chains
const WalletChain = {
  chainId: 137, // Polygon Mainnet's chain ID
  name: 'Polygon',
  currency: 'MATIC', // The native currency of Polygon
  explorerUrl: 'https://polygonscan.com', // Polygon blockchain explorer
  rpcUrl: 'https://polygon-rpc.com' // Public RPC for Polygon
}

// 3. Create modal
const metadata = {
  name: 'PayPangea',
  description: 'Decentralised payments made easy',
  url: 'https://my.paypangea.com',
  icons: ['https://my.paypangea.com/logo192.png']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [WalletChain],
  projectId,
  tokens: {
    137: {
      address: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359'
    }
  }
})


// Assets

export default function Content(props) {
  const { ...rest } = props;


  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();

	const payPangeaLogo = useColorModeValue(payPangeaLogoBlack, payPangeaLogoWhite);

  const toast = useToast();
  const navigate = useNavigate();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgCard = useColorModeValue("white", "navy.700");
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");


  const currentPageURL = window.location.search;
  const searchParams = new URLSearchParams(currentPageURL);

  const requestTkn = searchParams.get('tkn'); 
  const showPaymentData = searchParams.get('show') === 'true'; 
  const showCardBuy = searchParams.get('card') !== 'false';
  const showWalletConnect = searchParams.get('walletconnect') !== 'false';
  const payeeEmail = searchParams.get('email'); 
  const payeePhone = searchParams.get('phone'); 
  const isFixedNetwork = searchParams.get('fixednetwork') !== 'false';

  const [wallet, setWallet] = useState('');
  const [balance, setBalance] = useState('');
  const [receiverWallet, setReceiverWallet] = useState('');
  const [network, setNetwork] = useState('');
  const [amount, setAmount] = useState('');
  const [amountCrypto, setAmountCrypto] = useState('');
  const [currency, setCurrency] = useState('');
  const [token, setToken] = useState('');
  const [tokenAddress, setTokenAddress] = useState('');
  const [transactionFee, setTransactionFee] = useState('-');
  const [walletUsername, setWalletUsername] = useState('');
  const [isUserVerified, setIsUserVerified] = useState(true);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('Processing ...');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [isTransferOnly, setIsTransferOnly] = useState(true);

  const [isPaid, setIsPaid] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payTkn, setPayTkn] = useState('');
  const [cardPayTkn, setCardPayTkn] = useState('');
  const [payOutcome, setPayOutcome] = useState('');
  const [outcomeMessage, setOutcomeMessage] = useState('');
  const [isOverlay, setIsOverlay] = useState(false);

  const [isOutcomeModalOpen, setIsOutcomeModalOpen] = useState(false);
  const [isTopupModalOpen, setIsTopupModalOpen] = useState(false);
  const [isWalletTopupModalOpen, setIsWalletTopupModalOpen] = useState(false);
  
  const [showButtons, setShowButtons] = useState('email');

  const walletDisplayText = useBreakpointValue({
    base: `${receiverWallet.substr(0,8)}...${receiverWallet.slice(-6)}`,
    md: receiverWallet,
  });

  const sendMessageToParent = (message) => {
    window.parent.postMessage(message, "*");
  };

  useEffect(() => {
    sendMessageToParent({
      event_id:EVENTS.PAYPANGEA_WIDGET_OPEN
    });
  }, []);

  
  let transak;

  const openModalWindow = (name) => {
    console.log(name);
    setIsLoginOpen(name === 'loginModal');
    setIsPinModalOpen(name === 'pinModal');
    setIsCardModalOpen(name === 'pangeacardModal');
    setIsModalOpen(name === 'pinInputModal');
    setIsOutcomeModalOpen(name === 'outcomeModal');
    setIsTopupModalOpen(name === 'topupModal');
    setIsWalletTopupModalOpen(name === 'walletTopupModal');
    setIsOverlay(name === 'overlay');

    if (name==='walletConnectopen') {
      open();
    }
  }


  useEffect(() => {
    // Cleanup code
    return () => {
      console.log(`close transak ${new Date().toLocaleTimeString()}`)
      if (transak) transak.close();
    };
  }, []);

  const topupWithFiat = () => {
    console.log(`start transak ${new Date().toLocaleTimeString()}`)

    const adjustedNetwork = network === "mainnet" ? "ethereum" : network;
    const transak = new transakSDK({
      apiKey: process.env.REACT_APP_TRANSAK_ID,
      environment: process.env.REACT_APP_TRANSAK_ENVIRONMENT,
      defaultFiatAmount: amount < 27 ? 30 : Math.ceil(parseInt(amount) * 1.03),
      fiatCurrency: currency,
      ...(isFixedNetwork
        ? {
            cryptoCurrencyCode: token,
            network: adjustedNetwork,
          }
        : {
            defaultNetwork: 'polygon',
            defaultCryptoCurrency: 'USDC',
            walletAddress: wallet,
          }),
    });
    console.log(transak)

    // To get all the events
    transak.on(transak.ALL_EVENTS, (data) => {
      console.log(data);
    });

    // This will trigger when the user closed the widget
    transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {
      console.log(`close transak (TRANSAK_WIDGET_CLOSE) ${new Date().toLocaleTimeString()}`)
      transak.close();
      openModalWindow('topupModal');
    });

    // This will trigger when the user marks payment is made
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
      console.log(`close transak (TRANSAK_ORDER_SUCCESSFUL) ${new Date().toLocaleTimeString()}`)
      console.log(orderData);
      transak.close();
      setIsOverlay(false);
      setText('Processing ...');
      closeWalletTopupModal();
      setIsLoginOpen(false);
      setHasTopedUp(true);
      openTopupModal();

    });

    transak.init();

    return () => {
      console.log(`close transak ${new Date().toLocaleTimeString()}`)
      transak.close();
    };
  };

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const event = useWeb3ModalEvents()

  const topupWalletConnect = async () => { 
    console.log("wallet connect");

    if (isConnected) {
      console.log(`chainId ${chainId}`);
      console.log(`address ${address}`);
      console.log(`isConnected ${isConnected}`);
      console.log(walletProvider)
      getBalance();
      openModalWindow('walletTopupModal');
    } else {
      openModalWindow('walletConnectopen');
    }

  }

//   useEffect(() => {
//     console.log(`chainId ${chainId}`);
//     console.log(`address ${address}`);
//     console.log(`isConnected ${isConnected}`);

//     if (isConnected) {
//       console.log("disconnect");
//     }
//  }, [isConnected]);

  const VerifiedIcon = () => {
    if (isUserVerified) {
      return (
        <Icon as={FaCheckCircle} boxSize={4} color="blue.400" />
      );
    } else {
      return (
        <Icon as={FaTimesCircle} boxSize={4} color="red.400" />
      );
    
    }
  };

  const payRecepient = walletUsername===''?receiverWallet:walletUsername;

  const [hasTopedUp, setHasTopedUp] = useState(false);

  useEffect(() => {
     console.log(event)
     console.log(event.data)
     console.log(event.data.event)

     if (event) {
        if (event.data.event === 'SELECT_WALLET') {
          getBalance();
          openModalWindow('none')
        }
        if (event.data.event === 'CONNECT_SUCCESS') {
          getBalance();
          openWalletTopupModal();
        }
        if (event.data.event === 'MODAL_CLOSE') {
          getBalance();
          if (isConnected) {
            openModalWindow('walletTopupModal');
          } else {
            openModalWindow('topupModal');
          }
        }
    };
  }, [event, hasTopedUp]);

  useEffect(() => {
    console.log(hasTopedUp)
    if (hasTopedUp) {
      const interval = setInterval(() => {
        getWalletBalance();
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [hasTopedUp]); 

  useEffect(() => {
    if (isTopupModalOpen) {
      getWalletBalance();
    }
  }, [isTopupModalOpen, wallet]);

  async function getWalletBalance(){

    if (wallet === '') {
      console.log('no wallet data')
      return;
    }

    console.log(`wallet ${wallet}`);
    
    fetch(process.env.REACT_APP_API_URL+'pay/balance-by-address', {
      method: 'POST',
      body: JSON.stringify({
        "address" : wallet
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        if (balance !== data.balance) {
          console.log('balance changed');
          setBalance(data.balance);
          setHasTopedUp(false);

          if (parseFloat(data.balance)>=parseFloat(amountCrypto)) {
            console.log('can pay');
            closeTopupModal();
            setText('Processing ...');
            closeWalletTopupModal();


            if (cardPayTkn==='') {
              payToCryptoAddress();
            } else {
              setCardStage(1);

              openCardModal();
            }
          } else {
            console.log('can not pay')
          }
        }

      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });

  }

  async function getBalance(){

    const USDTAddress = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
    const USDCAddress = '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359'

    if(isConnected) {
      try {
        const ethersProvider =  new BrowserProvider(walletProvider)
        const signer = await ethersProvider.getSigner()
        // The Contract object
        const USDTContract = new Contract(USDTAddress, erc20Abi, signer)
        const USDTBalance = await USDTContract.balanceOf(address)
    
        console.log(formatUnits(USDTBalance, 6))

        const USDCContract = new Contract(USDCAddress, erc20Abi, signer)
        const USDCBalance = await USDCContract.balanceOf(address)
    
        console.log(formatUnits(USDCBalance, 6))
        
        const balance = await ethersProvider.getBalance(address);

        console.log(formatUnits(balance, 18))

        if (token === 'USDT') {
          setWalletBallance(formatUnits(USDTBalance, 6));
        }

        if (token === 'USDC') {
          setWalletBallance(formatUnits(USDCBalance, 6));
        }

        if (token === 'MATIC') {
          setWalletBallance(formatUnits(balance, 18));
        }

        // if (!isWalletTopupModalOpen) openWalletTopupModal();
      } catch (e) {
        console.log(e)
      }
    } else {
      console.log('not connected')
    }

  }

  async function payFromWallet(amountPay){

    const USDTAddress = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
    const USDCAddress = '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359'

    if(isConnected && wallet!=='' && amountPay>=amountCrypto) {

      setText('Please confirm the transaction on your wallet');
      setIsOverlay(true);
      const ethersProvider =  new BrowserProvider(walletProvider)
      const signer = await ethersProvider.getSigner()
      // The Contract object

      const contractAddress = token === 'USDT' ? USDTAddress : USDCAddress;
      const USDContract = new Contract(contractAddress, erc20Abi, signer)
      
      try {
        const tx = await USDContract.transfer(wallet, parseUnits(amountPay.toString(), 6)); 

        setText('Waiting for transaction to be processed by connected wallet...');

        await tx.wait(); // Wait for transaction confirmation

        console.log(tx)
        console.log("Transfer successful:", tx.hash); // Log transaction hash
        setIsOverlay(false);
        setText('Processing ...');
        closeWalletTopupModal();
        setIsLoginOpen(false);
        setHasTopedUp(true);
        setIsTopupModalOpen(true);
      } catch (error) {
        setIsOverlay(false);
        setText('Processing ...');
        setIsTopupModalOpen(true);
        console.error("Transfer failed:", error);
        toast({
          title: 'Error.',
          description: "Something went wrong, please try again",
          status: 'error',
        })
      }
    } else {
      console.log('not connected')
    }

  }

  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [cardStage, setCardStage] = useState(0);

  const openCardModal = () => {
    openModalWindow('pangeacardModal');
    // setIsLoginOpen(false);
    // setIsCardModalOpen(true);
  };

  const closeCardModal = () => {
    setIsCardModalOpen(false);
  };



  const [walletBalance, setWalletBallance] = useState(0);

  const openModal = () => {
    openModalWindow('pinInputModal');
    // setIsModalOpen(true);
    // setIsCardModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openOutcomeModal = () => {
    openModalWindow('outcomeModal')
    // setIsOutcomeModalOpen(true);
  };

  const closeOutcomeModal = () => {
    console.log('close')
    setIsOutcomeModalOpen(false);

    if (transak) {
      
      console.log(`close transak (OUTCOME) ${new Date().toLocaleTimeString()}`)
      transak.close();
    }

    sendMessageToParent({
      event_id:EVENTS.PAYPANGEA_WIDGET_CLOSE_REQUEST,
      outcome: payOutcome,
    });

    if (redirectUrl !== '') {
      setTimeout(function() {
        window.location.href = redirectUrl;
    }, 500);
    }
  };

  const openTopupModal = () => {
    getWalletBalance();
    openModalWindow('topupModal');
    // setIsTopupModalOpen(true);
  };

  const closeTopupModal = () => {
    setHasTopedUp(false);
    setIsTopupModalOpen(false);
  };

  const openWalletTopupModal = () => {
    getWalletBalance();
    openModalWindow('walletTopupModal');
    // setIsWalletTopupModalOpen(true);
  };

  const closeWalletTopupModal = () => {
    setIsWalletTopupModalOpen(false);
  };

  const cancelWalletTopupModal = () => {

    disconnect();
    openModalWindow('topupModal');
  };

  const onDisconnect = () => {
    console.log('disconnect');

    console.log(`chainId ${chainId}`);
    console.log(`address ${address}`);
    console.log(`isConnected ${isConnected}`);

    // if (chainId === 0 || !isConnected) {
    //   openModalWindow('walletConnectopen');
    // } else {
    disconnect();
    openModalWindow('topupModal');

    console.log(`isConnected ${isConnected}`);
    // }
  };

  const closeBuyCrypto = () => {
    setIsTopupModalOpen(false);
    topupWithFiat();
    // navigate(`/admin/home`);
  };

  const closeTransferCrypto = () => {
    setIsTopupModalOpen(false);
    topupWalletConnect();
  };

  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const openLoginModal = () => {
    openModalWindow('loginModal');
    // setIsLoginOpen(true);
    // setIsCardModalOpen(false);
  };

  const closeLoginModal = (isNew) => {
    console.log(isNew);

    const userData = GetUserData();
    console.log(userData)

    setIsLoginOpen(false);

    if (userData!==null) {
      setWallet(userData.address)
    }

    if (isNew!==undefined) {
      if (!isNew) {
        payToCryptoAddress();
      } else {
        openPinModal();
      }
    }

  };


  const [isPinModalOpen, setIsPinModalOpen] = useState(false);

  const openPinModal = () => {
    openModalWindow('pinModal');
    // setIsPinModalOpen(true);
  };

  const closePInModal = () => {
    setIsPinModalOpen(false);
  };

  const pinSet = (address) => {
    console.log(address);
    setWallet(address);
    closePInModal();
    payToCryptoAddress();
  }

  useEffect(()=>{
    getTransactionData()
  },[])

  const getTransactionData = () => {

    fetch(process.env.REACT_APP_API_URL+'pay/get-request-data', {
      method: 'POST',
      body: JSON.stringify({
        "tkn" : requestTkn
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        console.log(data.paymentData.receiver)
        setReceiverWallet(data.paymentData.receiver)
        setNetwork(data.paymentData.chain)
        setAmount(data.paymentData.amount ? data.paymentData.amount : "")
        setToken(data.paymentData.token)
        setTokenAddress(data.paymentData.tokenAddress)
        setIsUserVerified(data.paymentData.verified)
        setWalletUsername(data.paymentData.username ? data.paymentData.username : '')
        setTitle(data.paymentData.title ? data.paymentData.title : '')
        setText(data.paymentData.text ? data.paymentData.text : '')
        setAmountCrypto(data.paymentData.amountUSDT ? data.paymentData.amountUSDT : '')
        setCurrency(data.paymentData.currency?data.paymentData.currency:"")
        setIsTransferOnly(data.paymentData.isContractCall?!data.paymentData.isContractCall:true)
        // getTransactionFee();

        if ((!payeeEmail || payeeEmail === '') && (!payeePhone || payeePhone === '')) {
          if (showPaymentData) {
            openModalWindow('none')
          } else {
            openModalWindow('loginModal')
          }
        } else if (payeeEmail !== '') {
          console.log(showPaymentData)
          if (showPaymentData) {
            openModalWindow('none')
          } else {
            openModalWindow('loginModal')
          }
        } else if (payeePhone !== '') {
          if (showPaymentData) {
            openModalWindow('none')
          } else {
            openModalWindow('loginModal')
          }
        }


      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const getTransactionFee = (token_address) => {

    console.log(`getTransactionFee ${token_address}`)
 
    if (!token_address) {
      token_address = '0x00'
    }

    fetch(process.env.REACT_APP_API_URL+'pay/estimate-gas', {
      method: 'POST',
      body: JSON.stringify({
        "chain" : 'mainnet',
        "amount": "0",
        "token": token_address
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        setTransactionFee(data.estimatedGas)
      }
      
    })
    .catch(err => {
        console.log("fetch error: " + err);
    });
  }

  const payToCryptoAddress = () => {
    setIsOverlay(true);
    setText('Processing ...');

    const endpoint = isTransferOnly
    ? process.env.REACT_APP_API_URL + 'pay/send-request'
    : process.env.REACT_APP_API_URL + 'pay/get-contract-request';

    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        "tkn" : requestTkn
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      setIsOverlay(false);
      console.log(data)

      if (data.status === 1) {
        setPayTkn(data.paymentData.tkn)

        openModal();

      } else if (data.status === 2) {
        setOutcomeMessage(`Insufficient balance`)

        openTopupModal();
      }
      
    })
    .catch(err => {
        setIsOverlay(false);
        console.log("fetch error: " + err);
    });
  }

  const submitPin = (submitedPin) => {
    console.log(`submitedPin ${submitedPin}`)
    setIsModalOpen(false);
    confirmPay(submitedPin)
  };


  const confirmPay = (pin) => {
    setIsOverlay(true);
    setText('Processing ...');

    const endpoint = isTransferOnly
    ? process.env.REACT_APP_API_URL + 'pay/verify-payment'
    : process.env.REACT_APP_API_URL + 'pay/verify-contract-request';

    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({
        "tkn": payTkn,
        "passkey": pin,
      }),
      headers: ApiHeader('auth')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      if (data.status === 1) {
        setPayOutcome('SUCCESS');
        setIsPaid(true);
      } else if (data.status === 2) {
        setPayOutcome('FAILURE');
        setOutcomeMessage(`Insufficient balance`)
      } else {
        setPayOutcome('FAILURE');
      }
      openOutcomeModal(true);

      if (data.redirect && data.redirect!== "") {
        setRedirectUrl(data.redirect);
      }

      setIsOverlay(false);
    })
    .catch(err => {
        console.log("fetch error: " + err);

      setIsOverlay(false);
        toast({
          title: 'Error.',
          description: "Something went wrong, please try again",
          status: 'error',
        })
    });
  }

  const submitPinCard = (submitedPin) => {
    console.log(submitedPin)
    setIsCardModalOpen(false);
    payCard(submitedPin.pin, cardPayTkn, payTkn);
  }

  const submitCardData = (submitedCard) => {
    startPayCard(submitedCard)
  }

  const startPayCard = (submitedPin) => {
    setIsOverlay(true);
    setText('Processing ...');

    fetch(process.env.REACT_APP_API_URL+'pay/pay-by-card', {
      method: 'POST',
      body: JSON.stringify({
        "tkn": requestTkn,
        "card": submitedPin.card,
        "ccv": submitedPin.ccv,
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)
      setIsOverlay(false);

      if (data.status === 1) {
        setPayTkn(data.paymentData.tkn)
        setCardPayTkn(data.token);
        setCardStage(1);
        setWallet(data.user.address);

        
      } else {
        setOutcomeMessage(`Insufficient balance`)
        setWallet(data.user.address);
        setPayTkn(data.paymentData.tkn)
        setCardPayTkn(data.token);

        closeCardModal();
        openTopupModal();
      }
    })
    .catch(err => {
        console.log("fetch error: " + err);

      closeCardModal();

      setIsOverlay(false);
        toast({
          title: 'Error.',
          description: "Something went wrong, please try again",
          status: 'error',
        })
    });
  }

  const payCard = (pin, token, reqtkn) => {
    setIsOverlay(true);
    setText('Processing ...');

    fetch(process.env.REACT_APP_API_URL+'pay/verify-by-card', {
      method: 'POST',
      body: JSON.stringify({
        "tkn": reqtkn,
        "token": token,
        "passkey": pin,
      }),
      headers: ApiHeader('basic')
    })
    .then(response => { 
      return response.json();
    })
    .then(responseData => {
      return responseData;
    })
    .then(data => {
      console.log(data)

      closeCardModal();

      if (data.status === 1) {
        setPayOutcome('SUCCESS');
        openOutcomeModal(true);
      } else if (data.status === 2) {
        setPayOutcome('FAILURE');

        setOutcomeMessage(`Insufficient balance`)

        openTopupModal();
      } else {
        setPayOutcome('FAILURE');
        openOutcomeModal(true);
      }

      if (data.redirect && data.redirect!== "") {
        setRedirectUrl(data.redirect);
      }
      
      setIsOverlay(false);
    })
    .catch(err => {
        console.log("fetch error: " + err);

        closeCardModal();
      setIsOverlay(false);
        toast({
          title: 'Error.',
          description: "Something went wrong, please try again",
          status: 'error',
        })
    });
  }

  return (
    <>
      {!isPaid && (
        <>
        {!showPaymentData && (
        <Flex direction='column' h='90vh' align='center' justify='center'>
            <Card
              backgroundRepeat='no-repeat'
              bg={bgCard}
              p='30px'
              mb='20vh'
              w='fit-content'>
                <Box 
                  display='block'
                  h={'100%'}
                  mb={{ sm: '0px', md: '0px' }}>
                    <Image 
                      src={payPangeaLogo} 
                      h='56px'
                      mx='auto'
                      alt="PayPangea Logo" />
                </Box>
            </Card>

            <Button 
                onClick={()=>{
                  if (transak) {
                    console.log(`close transak (BUTTON) ${new Date().toLocaleTimeString()}`)
                    transak.close();
                  };

                  sendMessageToParent({
                    event_id:EVENTS.PAYPANGEA_WIDGET_CLOSE_REQUEST,
                    outcome: payOutcome,
                  });
                  
                }} 
                variant='brand' 
                mt="20px" 
                size="lg" 
                w="100%">
                Close
            </Button>
          </Flex>
        )}

        {showPaymentData && (
          <>
            <Card
              backgroundImage={InvoiceBg}
              backgroundRepeat='no-repeat'
              bgSize='cover'
              bgPosition='10%'
              p={{base:"20px", md:"60px"}}
              pt={{base:"20px", md:"30px"}}
              pb={{base:"70px", md:"20px"}}>
              <Flex mb={{ base: "0px", md: "20px" }} direction={{base:"column", md:"row"}}>
                
                <Box 
                  display='block'
                  h={'100%'}
                  mb={{ sm: '0px', md: '0px' }}>
                    <Image 
                      src={payPangeaLogo} 
                      mx='auto'
                      alt="PayPangea Logo" />
                </Box>
                <Flex direction='column' color='white' h='100%' w='100%'>
                </Flex>
              </Flex>
            </Card>

            <Flex direction='column' h='70vh' align='center' justify='center'>
            <Card
                borderRadius="5px 5px 0 0" 
                p="20px"
                pb="40px">
                <Flex mb={{ base: "0px", md: "0px" }} direction="column">
                  <Grid
                    templateColumns="1fr 2fr" // 1/3 and 2/3 width
                    gap={2} // Adjust the gap between grid items
                  >

                  {title && title.trim()!=='' && (
                    <>
                      <GridItem colSpan={1}>
                        <Text fontSize="md" mt="0px" fontWeight='regular'>
                          Item:
                        </Text>
                      </GridItem>

                      <GridItem colSpan={1}>
                        <Text fontSize="md" mt="0px" fontWeight='bold'>
                          {title}
                        </Text>
                      </GridItem>
                    </>
                  )}

                  {text && text.trim()!=='' && (
                    <>
                      <GridItem colSpan={1}>
                        <Text fontSize="md" mt="0px" fontWeight='regular'>
                          Description:
                        </Text>
                      </GridItem>

                      <GridItem colSpan={1}>
                        <Text fontSize="md" mt="0px" fontWeight='regular'>
                          {text}
                        </Text>
                      </GridItem>
                    </>
                  )}

                    <GridItem colSpan={1}>
                      <Text fontSize="md" mt="0px" fontWeight='regular'>
                        Recepient:
                      </Text>
                    </GridItem>

                    <GridItem colSpan={1}>
                      {payRecepient.length > 30 ? `${payRecepient.substr(0,8)}...${payRecepient.slice(-6)}` : payRecepient}
                    </GridItem>

                    <GridItem colSpan={1}>
                      <Text fontSize="md" mt="0px" fontWeight='regular'>
                        Verified:
                      </Text>
                    </GridItem>

                    <GridItem colSpan={1}>
                      {VerifiedIcon()}
                    </GridItem>

                  {amount && amount.trim()!=='' && (
                    <>
                      <GridItem colSpan={1}>
                        <Text fontSize="md" mt="0px" fontWeight='regular'>
                          Amount:
                        </Text>
                      </GridItem>

                      <GridItem colSpan={1}>
                        <Text fontSize="md" mt="0px" fontWeight='regular'>
                          {amount} {currency}
                        </Text>
                      </GridItem>
                    </>
                  )}

                    <GridItem colSpan={1}>
                      <Text fontSize="md" mt="0px" fontWeight='regular'>
                        Amount in tokens:
                      </Text>
                    </GridItem>

                    <GridItem colSpan={1}>
                      {amountCrypto} {token}
                    </GridItem>

                  </Grid>
                  
                </Flex>
              </Card>

                <Button 
                    onClick={()=>{
                      openModalWindow('loginModal');
                    }} 
                    variant='brand' 
                    mt="20px" 
                    size="lg" 
                    w="100%">
                    Pay
                </Button>
              </Flex>
          </>
        )}
        </>
      )}

      {isPaid && (
        <>
        

            <Card
              backgroundImage={InvoiceBg}
              backgroundRepeat='no-repeat'
              bgSize='cover'
              bgPosition='10%'
              p={{base:"20px", md:"60px"}}
              pt={{base:"20px", md:"30px"}}
              pb={{base:"70px", md:"20px"}}>
              <Flex mb={{ base: "0px", md: "20px" }} direction={{base:"column", md:"row"}}>
                
                <Box 
                  display='block'
                  h={'100%'}
                  mb={{ sm: '0px', md: '0px' }}>
                    <Image 
                      src={payPangeaLogo} 
                      mx='auto'
                      alt="PayPangea Logo" />
                </Box>
                <Flex direction='column' color='white' h='100%' w='100%'>
                </Flex>
              </Flex>
            </Card>

            <Flex direction='column' h='70vh' align='center' justify='center'>
            <Card
                borderRadius="5px 5px 0 0" 
                p="20px"
                pb="40px">
                  <Text fontSize="xl" mt="0px" fontWeight='regular' textAlign='center'>
                    Thank you for your payment!
                  </Text>
              </Card>
            </Flex>

        </>
      )}
      

      <LoginModal isOpen={isLoginOpen} onClose={closeLoginModal} email={payeeEmail} phone={payeePhone} openCardModal={openCardModal} showButtons={showButtons}/>

      <SetPinModal isOpen={isPinModalOpen} onClose={closePInModal} pinSet={pinSet}/>

      <PangeaCardModal isOpen={isCardModalOpen} openLogin={openLoginModal} onClose={closeCardModal} submitPin={submitPinCard} submitCardData={submitCardData} recepient={walletUsername===''?receiverWallet:walletUsername} isUserVerified={isUserVerified} amount={`${amount} ${currency}`} amountCrypto={`${amountCrypto} ${token}`} item={title} stage={cardStage}/>

      <PinInputModal isOpen={isModalOpen} onClose={closeModal} submitPin={submitPin} recepient={walletUsername===''?receiverWallet:walletUsername} isUserVerified={isUserVerified} amount={`${amount} ${currency}`} amountCrypto={`${amountCrypto} ${token}`} item={title}/>

      <OutcomeModal isOpen={isOutcomeModalOpen} onClose={closeOutcomeModal} closeBuyCrypto={closeBuyCrypto} closeTransferCrypto={closeTransferCrypto} outcome={payOutcome} message={outcomeMessage}/>
      
      <TopUpModal isOpen={isTopupModalOpen} onClose={closeOutcomeModal} closeBuyCrypto={closeBuyCrypto} closeTransferCrypto={closeTransferCrypto} hasTopedUp={hasTopedUp} outcome={payOutcome} message={outcomeMessage} walletBalance={balance} amount={amountCrypto} coin={token} onRefresh={getWalletBalance} showCardBuy={showCardBuy} showWalletConnect={showWalletConnect}/>

      <WalletTopUpModal isOpen={isWalletTopupModalOpen} onClose={cancelWalletTopupModal} onRefresh={getBalance} onDisconnect={onDisconnect} payFromWallet={payFromWallet} walletBalance={walletBalance} amount={amountCrypto} coin={token}/>

      <Overlay isOpen={isOverlay} text={text}/>
    </>
  );
}
